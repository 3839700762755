/* eslint-disable @next/next/no-sync-scripts */
import { NextPage } from "next"
import Image from "next/image"
import { AnchorLinkClass } from "../../styles/tailwind.helpers"
import SuperSearch from "../../components/search/SuperSearch"
import PrivacyStatementModal from "../../components/shared/PrivacyStatementModal"
import useSearchPageStateAndHandlers from "../../hooks/useSearchPageStateAndHandlers"
import AdvancedSearchModal from "../../components/AdvancedSearchModal"

const SearchPage: NextPage = (_props, _context) => {
    // Search page hook for all state and handlers
    const {
        authenticated,
        handleRunSearch,
        handleTogglePrivacyModal,
        handleToggleAdvModal,
        showPrivacyModal,
        showAdvSearchModal,
        advancedSearchState,
        handleSetAdvSearchState,
        clearAdvSearchState,
    } = useSearchPageStateAndHandlers()

    return authenticated ? (
        <div className='flex flex-col space-y-16'>
            <div>
                <header className={`mt-32 my-16 mx-auto max-w-lg flex flex-row justify-center`}>
                    <Image height={96} width={96} src={"/logos/jasmine.svg"} alt={"Jasmine logo"} />
                    <h1 className={`font-merriweather my-auto text-4xl sm:text-5xl subpixel-antialiased`}>
                        Jasmine Search
                    </h1>
                </header>
                <SuperSearch defaultQuery={""} advSearchState={advancedSearchState} handleRunSearch={handleRunSearch} />
                <div className='mt-1 w-full text-center mx-auto max-w-xl justify-center'>
                    <section className='min-w-full text-right'>
                        <button
                            onClick={() => handleToggleAdvModal(true)}
                            className={`font-sourceSansPro ${AnchorLinkClass} no-underline hover:underline`}
                        >
                            Advanced search
                        </button>
                    </section>
                </div>
            </div>
            {/* <SearchHistory /> */}
            {showPrivacyModal ? <PrivacyStatementModal closeModal={handleTogglePrivacyModal} /> : null}
            {showAdvSearchModal ? (
                <AdvancedSearchModal
                    closeModal={handleToggleAdvModal}
                    setAdvSearchState={handleSetAdvSearchState}
                    advSearchState={advancedSearchState}
                    clearAdvSearchState={clearAdvSearchState}
                />
            ) : null}
        </div>
    ) : null
}

export default SearchPage